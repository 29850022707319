<template>
    <div class="login-body">
        <div class="login-image">
            <img :src="'layout/images/pages/login-' + loginColor + '.png'" alt="atlantis" />
        </div>
        <div class="login-panel p-fluid">
            <div class="flex flex-column">
                <div class="flex align-items-center mb-6 logo-container">
                    <img :src="'layout/images/appname-' + color + '.png'" class="login-appname" />
                </div>
                <div class="form-container">
                    <span class="p-input-icon-left">
                        <i class="pi pi-user"></i>
                        <InputText v-model="nickname" type="text" placeholder="Username" />
                    </span>
                    <span class="p-input-icon-left">
                        <i class="pi pi-key"></i>
                        <InputText v-model="password" type="password" placeholder="Password" v-on:keyup.enter="handleLogin" />
                    </span>
                </div>
                <div class="button-container">
                    <Button type="button" label="Login" @click="handleLogin" />
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from '@/utilities/Axios.js'
import VueJwtDecode from 'vue-jwt-decode'
import registerServiceWorker from '@/service/PushNotification.js'
export default {
    data() {
        return {
            nickname: '',
            password: '',
            registerServiceWorker
        }
    },
    created() {
        this.loadRecaptcha()
    },
    computed: {
        loginColor() {
            if (this.$appState.colorScheme === 'light') return 'ondark'
            return 'onlight'
        },
        color() {
            if (this.$appState.colorScheme === 'light') return 'dark'
            return 'light'
        },
    },
    methods: {
        async loadRecaptcha() {
            await this.$recaptchaLoaded()
        },
        async handleLogin() {
            try {
                // Execute reCAPTCHA with action "login".
                const token = await this.$recaptcha('login')
                if (!token) throw 'Recaptcha not valid!'
                const response = await axios.post('/login', {
                    nickname: this.nickname,
                    password: this.password,
                })
                console.log('Response status:', response.status)
                console.log('Response data:', response.data)
                //toast
                this.$toast.add({
                    severity: 'success',
                    summary: 'Accesso Eseguito',
                    detail: 'Credenziali corrette',
                    life: 2000, // display for 2 second
                })
                localStorage.setItem('token', response.data)
                this.requestPermission()
                this.redirectRuolo(VueJwtDecode.decode(response.data).ruolo)
            } catch (error) {
                console.error('Error:', error)
                if (!error.response) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Errore',
                        detail: 'Errore generico',
                        life: 2000, // display for 2 second
                    })
                    return
                }
                if (error.response.status === 401) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Errore',
                        detail: 'Reinserisci le credenziali',
                        life: 2000, // display for 2 second
                    })
                }
                if (error.response.status === 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Errore',
                        detail: 'Utente non',
                        life: 1000, // display for 1 second
                    })
                } else if (error.response.status === 404) {
                    console.log(this.$toast)
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Errore',
                        detail: 'Utente non trovato',
                        life: 1000, // display for 1 second
                    })
                }
            }
        },
        redirectRuolo(ruolo) {
            switch (ruolo) {
                case 'Sviluppatore':
                    this.$router.push('/direzione')
                    break
                case 'Direzione':
                    this.$router.push('/direzione')
                    break
                case 'Direzione Commerciale':
                    this.$router.push('/direzione')
                    break
                case 'Amministrazione':
                    this.$router.push('/direzione')
                    break
                case 'Ufficio Acquisti':
                    this.$router.push('/direzione')
                    break
                case 'Tecnico':
                    this.$router.push('/')
                    break
                default:
                    this.$router.push('/')
                    break
            }
        },
        requestPermission() {
            console.log('Requesting permission for notifications')
            if ('Notification' in window) {
                const previusPermissionStatus = Notification.permission
                Notification.requestPermission().then(async permission => {
                    if (permission === 'granted' && previusPermissionStatus !== 'granted') {
                        new Notification('Benvenuto', {
                            body: 'Sei stato abilitato a ricevere notifiche',
                        })
                        try {
                            this.serviceWorkerRegistration = await this.registerServiceWorker()
                        } catch (error) {
                            console.log(error)
                            this.$toast.add({
                                severity: 'error',
                                summary: 'Errore',
                                detail: error.message,
                                life: 5000,
                            })
                        }
                    }
                })
            } else {
                console.log('This browser does not support notifications.')
            }
        },
    },
}
</script>
